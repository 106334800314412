import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

const AdvantageBanner = () => {

    // Querying and storing Data Stored in Gatsby GraphQL query.

    const initial_data_query = useStaticQuery(graphql`
    query {
        wpgraphql {
            pages(where: { title: "The Tamarind Advantage" }) {
                nodes {
                    acf_Tamarind_Advantage_Banner {
                        advantageBannerTitle
                        advantageBannerDescr
                        advantageBanner {
                            altText
                            sourceUrl
                        }
                        advantageBannerXs {
                            altText
                            sourceUrl
                        }
                    }
                }
            }
        }
    }
    

    `)

    let initial_state_data = initial_data_query.wpgraphql;

    // Storing this data as the State so that we can update the state later with Live Data
    const [existingData, updateData] = useState(initial_state_data);

    // Fetching the Live data from the created WORDPRESS API
    useEffect(() => {
        let curr_api = process.env.GATSBY_API_URL;

        fetch(`${curr_api}/graphql`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                query: `
        {
            pages(where: { title: "The Tamarind Advantage" }) {
                nodes {
                    acf_Tamarind_Advantage_Banner {
                        advantageBannerTitle
                        advantageBannerDescr
                        advantageBanner {
                            altText
                            sourceUrl
                        }
                        advantageBannerXs {
                            altText
                            sourceUrl
                        }
                    }
                }
            }
        }
        
        `,
            }),
        })
            .then(res => res.json())
            .then(data => updateData(data.data))
            .catch(error => console.log(`Some Error Occurred! The Error :${error}`))
    }, [])


    // Storing All Live Data in Variables to display it

    let bannerData = existingData.pages.nodes[0].acf_Tamarind_Advantage_Banner;

    return (
        <div className="banner_section">
            <div className="inner">


                {/* <div className='img_lg xs-hide'>
                        {
                            bannerData.advantageBanner ? (
                                <img src={bannerData.advantageBanner.sourceUrl} alt={bannerData.advantageBanner.altText}/>
                            ):null
                        }
                    </div>

                    <div className='img_xs xs-show'>
                        {
                            bannerData.advantageBannerXs ? (
                                <img src={bannerData.advantageBannerXs.sourceUrl} alt={bannerData.advantageBannerXs.sourceUrl}/>
                            ):null
                        }
                    </div> */}


                <div className='banner_text'>
                    <div className='container'>

                        <div className='content_wrapper'>

                            {
                                bannerData.advantageBannerTitle ? (
                                    <h3 className='banner_title color_green section_title_lg'> {bannerData.advantageBannerTitle} </h3>
                                ) : null
                            }

                            <div className='banner_descr'>
                                {
                                    bannerData.advantageBannerDescr ? (
                                        require('html-react-parser')(
                                            bannerData.advantageBannerDescr
                                        )
                                    ) : null
                                }
                            </div>
                            <div className="year-logo-wrapper">
                                <img src="https://api.tamarindsearch.com/wp-content/uploads/2024/01/year-logo-tamarind.svg"></img>
                                <span>Leading Pharma Searches
                                    Globally Since 2011</span>
                            </div>
                        </div>

                    </div>
                </div>


            </div>
        </div>
    )
}

export default AdvantageBanner
