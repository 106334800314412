import React, { useState, useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

const AdvantageList = () => {

    // Querying and storing Data Stored in Gatsby GraphQL query.

    const initial_data_query = useStaticQuery(graphql`
    query {
        wpgraphql {
            tamarind_advantages(where: {orderby: {field: DATE, order: ASC}}) {
                edges {
                    node {
                        id
                        title
                        acf_Tamarind_Advantage {
                            advantageDescription
                            buttonText
                            buttonUrl
                            bgImgDesk {
                                altText
                                sourceUrl
                            }
                            bgImgXs {
                                altText
                                sourceUrl
                            }

                            mobileBackgroundImage {
                                altText
                                sourceUrl
                            }

                            
                        }
                    }
                }
            }
        }
    }
    

    `)

    let initial_state_data = initial_data_query.wpgraphql;

    // Storing this data as the State so that we can update the state later with Live Data
    const [existingData, updateData] = useState(initial_state_data);

    // Fetching the Live data from the created WORDPRESS API
    useEffect(() => {
        let curr_api = process.env.GATSBY_API_URL;

        fetch(`${curr_api}/graphql`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                query: `
        {
            tamarind_advantages(where: {orderby: {field: DATE, order: ASC}}) {
                edges {
                    node {
                        id
                        title
                        acf_Tamarind_Advantage {
                            advantageDescription
                            buttonText
                            buttonUrl
                            bgImgDesk {
                                altText
                                sourceUrl
                            }
                            bgImgXs {
                                altText
                                sourceUrl
                            }
                            mobileBackgroundImage {
                                altText
                                sourceUrl
                            }
                        }
                    }
                }
            }
        }
        
        `,
            }),
        })
            .then(res => res.json())
            .then(data => updateData(data.data))
            .catch(error => console.log(`Some Error Occurred! The Error :${error}`))
    }, [])


    // Storing All Live Data in Variables to display it

    let advantageList = existingData.tamarind_advantages.edges;
    console.log(advantageList)


    return (
        <div className='advantages_list'>
            <div className="container clearfix">
                <div className='inner'>
                    {
                        advantageList.map((item, index) => {
                            let loopIndex = index + 1;
                            let itemCls = (loopIndex % 2) === 0 ? 'item_even' : 'item_odd'
                            return (

                                <div className={`item ${itemCls}`} key={item.node.id} data-index={loopIndex}>
                                    <Link to={item.node.acf_Tamarind_Advantage.buttonUrl} className='banner_btn'>
                                        <div className='bg_banner' style={{ backgroundImage: `url(${item.node.acf_Tamarind_Advantage.bgImgDesk.sourceUrl})` }}>
                                            <div className="advantage-card">
                                                <div className="card-content">
                                                    <h3 className='title section_title_lg color_green'>{item.node.title}</h3>
                                                    <div className='descr'>
                                                        {
                                                            item.node.acf_Tamarind_Advantage.advantageDescription ? (
                                                                require('html-react-parser')(
                                                                    item.node.acf_Tamarind_Advantage.advantageDescription
                                                                )
                                                            ) : null
                                                        }
                                                    </div>
                                                </div>
                                                <div className="advantage-number-wrapper">
                                                    <img src={item.node.acf_Tamarind_Advantage.bgImgXs.sourceUrl} alt={loopIndex} />
                                                </div>

                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default AdvantageList

